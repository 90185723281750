import React, {useEffect, useState} from 'react';
import {DatePicker, Grid, Radio, Select} from "antd";
import moment from 'moment';
import IndexFundComparisonChart from "./IndexFundComparisonChart";
import axios from "axios";

const {useBreakpoint} = Grid;

function IndexFundComparison() {
    const [candidates, setCandidates] = useState([]);
    const [candidatesLoading, setCandidatesLoading] = useState(true);
    const [selectedCandidateCode, setSelectedCandidateCode] = useState('');
    const [selectedDataType, setSelectedDataType] = useState('point');
    const [dateRange, setDateRange] = useState({fromDate: moment('1970-01-01'), toDate: moment()});

    const screens = useBreakpoint();

    useEffect(() => {
        setCandidatesLoading(true);
        const url = '/candidates';
        axios.get(url)
            .then(response => {
                const data = response.data;
                setCandidates(data);
                setCandidatesLoading(false);
            })
    }, []);

    let candidateOptions = candidates.map(c => {
        return {
            label: `${c.name}（${c.code}）`,
            value: c.code,
            data: c,
        }
    });
    return (
        <div style={{padding: screens['sm'] ? 24 : 8, height: '100%'}}>
            <div>
                <Radio.Group onChange={(value) => setSelectedDataType(value.target.value)} defaultValue="point">
                    <Radio.Button value="point">点位</Radio.Button>
                    <Radio.Button value="value">估值</Radio.Button>
                </Radio.Group>
                <Select
                    id='indexSelect'
                    showSearch={screens['sm']}
                    bordered={true}
                    showArrow={false}
                    style={{width: 300}}
                    placeholder="选择指数"
                    onChange={(value) => {
                        setSelectedCandidateCode(value);
                        document.getElementById('indexSelect').blur();
                    }}
                    options={candidateOptions}
                    loading={candidatesLoading}
                    filterOption={(input, option) => (
                        option.data.code.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.data.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.data.name_pinyin.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )}
                >
                </Select>
                <DatePicker.RangePicker
                    id='datePicker'
                    defaultValue={[dateRange.fromDate, dateRange.toDate]}
                    ranges={{
                        '今年': [moment().startOf('year'), moment()],
                        '一年内': [moment().add(-1, 'years'), moment()],
                        '三年内': [moment().add(-3, 'years'), moment()],
                        '五年内': [moment().add(-5, 'years'), moment()],
                        '十年内': [moment().add(-10, 'years'), moment()],
                        '历史所有': [moment('1970'), moment()],
                    }}
                    format="YYYY/MM/DD"
                    onChange={(date) => {
                        setDateRange({fromDate: date[0], toDate: date[1]});
                        document.getElementById('datePicker').blur();
                    }}
                    inputReadOnly
                />
            </div>
            <div style={{marginTop: screens['sm'] ? 24 : 8, backgroundColor: '#fcfcfc', padding: screens['sm'] ? '24px 16px' : 0}}>
                <IndexFundComparisonChart type={selectedDataType} code={selectedCandidateCode}
                                          fromDate={dateRange.fromDate} toDate={dateRange.toDate}/>
            </div>
        </div>
    );
}

export default IndexFundComparison;