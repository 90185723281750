import React from 'react';
import {Card, Col, Row} from "antd";
import './Dashboard.css';
import StockPondComparison from "./stock_pond_comparison/StockPondComparison";
import ConvertibleBond from "./convertible_bond/ConvertibleBond";
import ConvertibleBondWatchdog from "./convertible_bond_watchdog/ConvertibleBondWatchdog";
import HoldingsBrief from "./holdings_brief/HoldingsBrief";
import TradeVolume from "./trade_volume/TradeVolume";
import Margin from "./margin/Margin";

function Dashboard(props) {

    return (
        <div id='container-dashboard'>
            <h1 id='title-dashboard'>监控中心</h1>
            <div id='card-container-dashboard'>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Card bordered={false}>
                            <HoldingsBrief/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <ConvertibleBondWatchdog/>
                        </Card>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>

                    <Col span={6}>
                        <Card bordered={false}>
                            <StockPondComparison/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <ConvertibleBond/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <TradeVolume/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <Margin/>
                        </Card>
                    </Col>

                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Dashboard;
