import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/charts';
import {Spin} from "antd";
import axios from 'axios';

function loadHistories(type, code, fromDate, toDate, setHistoriesLoading, setHistories) {
    if (!code) {
        return;
    }
    setHistoriesLoading(true);
    setHistories([]);
    let url = '/indexfundcomparison';
    axios.get(url, {
        params: {
            code: code,
            from_date: fromDate.format('YYYY-MM-DD'),
            to_date: toDate.format('YYYY-MM-DD'),
        }
    }).then(response => {
        const data = response.data;

        const valueData = data.histories.map(datum => {
            return {
                日期: datum.date,
                维度: type === 'point' ? '点位' : data.value_key.toUpperCase(),
                点位: datum.value,
            }
        });
        const avgData = data.avg_all.map(datum => {
            return {
                日期: datum.date,
                维度: '历史均值',
                点位: datum.value,
            }
        });
        const avg3Data = data.avg_3_years.map(datum => {
            return {
                日期: datum.date,
                维度: '三年均值',
                点位: datum.value,
            }
        });
        const avg5Data = data.avg_5_years.map(datum => {
            return {
                日期: datum.date,
                维度: '五年均值',
                点位: datum.value,
            }
        });
        const avg850Data = data.avg_850_days.map(datum => {
            return {
                日期: datum.date,
                维度: '850 日均值',
                点位: datum.value,
            }
        });

        const chartData = valueData.concat(avgData)
            .concat(avg3Data)
            .concat(avg5Data)
            .concat(avg850Data);

        setHistories(chartData);
        setHistoriesLoading(false);
    });
}

function buildChart(historiesLoading, histories) {
    const max = Math.max(...histories.map(h => h.点位 === null ? -999999 : h.点位));
    const min = Math.min(...histories.map(h => h.点位 === null ? 999999 : h.点位));
    const yPadding = (max - min) * 0.05;

    const config = {
        data: histories,
        xField: '日期',
        yField: '点位',
        seriesField: '维度',
        lineStyle: {
            lineWidth: 1,
        },
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: isFinite(yPadding) ? min - yPadding : 0,
            maxLimit: isFinite(yPadding) ? max + yPadding : 0,
        },
        xAxis: {
            line: histories.length > 0 ? {
                style: {
                    stroke: '#e6e6e6'
                }
            } : null,
            tickLine: null,
            tickMethod: scale => {
                const ticks = [];

                let prevDate = new Date(scale.values[0]);
                scale.values.forEach(dateString => {
                    const d = new Date(dateString);
                    if (d.getFullYear() !== prevDate.getFullYear()) {
                        ticks.push(dateString);
                        prevDate = d;
                    }
                });

                return ticks;
            },
            label: {
                formatter: date => new Date(date).getFullYear(),
            },
        },
        tooltip: {
            crosshairs: {
                type: 'xy',
                follow: true,
                text: (type, defaultContent) => {
                    if (type === 'y') {
                        defaultContent = '      ' + defaultContent.toFixed(4);
                    }
                    return {
                        content: type === 'y' ? defaultContent : '',
                        style: {
                            fontSize: 12,
                            fontWeight: 500,
                            textAlign: 'start',
                            textBaseline: type === 'y' ? 'bottom' : 'middle',
                        },
                    };
                },
                line: {
                    style: {
                        lineWidth: 1,
                        lineDash: [3, 3],
                    }
                },
                textBackground: {
                    style: {
                        opacity: 0,
                    },
                },
            },
        }
    };
    return (
        <div>
            <Spin size='middle' spinning={historiesLoading}>
                <Line {...config}/>
            </Spin>
        </div>
    );
}

function IndexFundComparisonChart(props) {
    const [historiesLoading, setHistoriesLoading] = useState(false);
    const [histories, setHistories] = useState([]);

    useEffect(() => {
        loadHistories(props.type, props.code, props.fromDate, props.toDate, setHistoriesLoading, setHistories)
    }, [props.type, props.code, props.fromDate, props.toDate]);

    return buildChart(historiesLoading, histories);
}

export default IndexFundComparisonChart;
