import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Spin} from 'antd';
import {Column} from '@ant-design/charts';
import './Margin.css';
import moment from "moment";

function Margin(props) {
    const [loading, setLoading] = useState(true);
    const [margins, setMargins] = useState([]);
    const [currentData, setCurrentData] = useState(null);


    const refreshData = () => {
        setLoading(true);
        const url = `/getmargins?from_date=2020-01-01`;

        axios.get(url)
            .then(response => {
                const data = response.data;
                const margins = data.map(datum => {
                    return {
                        日期: moment(datum.date).format('YYYY-MM-DD'),
                        类型: '融券额',
                        值: datum.sb,
                    }
                }).concat(
                    data.map(datum => {
                        return {
                            日期: moment(datum.date).format('YYYY-MM-DD'),
                            类型: '融资额',
                            值: datum.fb,
                        }
                    })
                );
                setCurrentData(data[data.length - 1]);
                setMargins(margins);
                setLoading(false);
            })
    };

    useEffect(() => {
        refreshData();
    }, []);

    const annotations = [];

    if (margins.length > 0) {
        annotations.push({
            type: 'line',
            start: ['min', currentData.fb + currentData.sb],
            end: ['max', currentData.fb + currentData.sb],
            style: {
                lineWidth: 1,
                lineDash: [3, 3],
                stroke: 'red',
            },
        });
    }

    const config = {
        data: margins,
        isStack: true,
        xField: '日期',
        yField: '值',
        seriesField: '类型',
        autoFit: true,
        lineStyle: {
            lineWidth: 1,
        },
        legend: false,
        yAxis: {
            label: null,
            grid: {
                line: null,
            },
            minLimit: 1,
        },
        xAxis: {
            label: null,
            line: null,
            tickLine: null,
        },
        tooltip: {
            formatter: (datum) => {
                return {name: datum.类型, value: Math.round(datum.值 / 100000000) + ' 亿元'};
            },
            position: 'bottom',
        },
        annotations: annotations,
        meta: {
            ['日期']: {
                sync: false,
            },
        },
    };

    return (
        <div id='container-trade-margin'>
            <div id='titlebar-trade-margin'>
                <span id='title-trade-margin' style={{display: 'inline-block'}}>两融余额</span>
                <span id='title-tail-trade-margin'>{currentData && moment(currentData.date).format('(MM/DD)')}</span>
            </div>
            <div id='subtitlebar-trade-margin'>
                <h3 id='subtitle-trade-margin'
                    style={{display: 'inline-block'}}>{currentData && Math.round((currentData.fb + currentData.sb) / 10000000) / 10}</h3>
                <span id='subtitle-tail-trade-margin'>{currentData && '亿元'}</span>
            </div>
            <div id='margin-chart-container'>
                <Spin id='spin-margin-chart' size='middle' spinning={loading}>
                    <Column id='margin-chart' {...config}/>
                </Spin>
            </div>
        </div>
    );
}

export default Margin;
