import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import {Button, Input, List, Popover} from 'antd';
import './ConvertibleBondWatchdog.css';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

function ConvertibleBondWatchdog(props) {
    const [loading, setLoading] = useState(true);
    const [watchdogs, setWatchdogs] = useState([]);

    const [newCode, setNewCode] = useState('');
    const [addPopupVisible, setAddPopupVisible] = useState(false);
    const newCodeInputRef = useRef(null);

    const refreshWatchdogs = () => {
        setLoading(true);
        const url = '/getconvertiblebondwatchdogs';

        axios.get(url)
            .then(response => {
                const data = response.data;
                setWatchdogs(data);
                setLoading(false);
            })
    }

    const addWatchdog = (code) => {
        const url = '/addconvertiblebondwatchdog';

        axios.post(url, {'code': code})
            .then(response => refreshWatchdogs())
    }

    const deleteWatchdog = (code) => {
        const url = '/deleteconvertiblebondwatchdog';

        axios.post(url, {'code': code})
            .then(response => refreshWatchdogs())
    }

    const handleVisibleChange = (newVisible) => {
        setAddPopupVisible(newVisible);
        if (newVisible) {
            setTimeout(() => {
                newCodeInputRef.current.focus();
            })
        }
    };

    useEffect(() => {
        refreshWatchdogs();
    }, [])

    return (
        <div id='container-convertible-bond-watchdog'>
            <div id='titlebar-convertible-bond-watchdog'>
                <h2 id='title-convertible-bond-watchdog' style={{display: 'inline-block'}}>可转债上市</h2>
                <Popover
                    id='popover-add-convertible-bond-watchdog'
                    trigger="click"
                    visible={addPopupVisible}
                    onVisibleChange={handleVisibleChange}
                    content={(
                        <Input
                            id='input-add-convertible-bond-watchdog'
                            placeholder='转债代码'
                            bordered={false}
                            value={newCode}
                            onChange={e => setNewCode(e.target.value)}
                            onPressEnter={e => {
                                const code = e.target.value;
                                addWatchdog(code);
                                setAddPopupVisible(false);
                                setNewCode('');
                            }}
                            ref={newCodeInputRef}
                        />
                    )}
                >
                    <Button
                        id='add-button-convertible-bond-watchdog'
                        type="link"
                        icon={<PlusOutlined/>}
                        size="middle"/>
                </Popover>
            </div>
            <List
                id="convertible-bond-watchdog-list"
                loading={loading}
                dataSource={watchdogs}
                renderItem={item => (
                    <List.Item
                        key={item.code}
                        actions={[
                            <Button danger type="link" icon={<MinusCircleOutlined/>} size="small"
                                    onClick={e => {
                                        setWatchdogs(watchdogs.filter(i => i.code !== item.code));
                                        deleteWatchdog(item.code);
                                    }}/>
                        ]}
                        className={item.eta && item.eta < 5 && item.eta > 0 ? 'li-arriving' : 'li-not-arriving'}
                    >
                        <div>
                            <span style={{fontSize: 14}}>{item.name}</span>
                            <span style={{fontSize: 12, opacity: 0.3, marginLeft: 2}}>({item.code})</span>
                            {item.eta ? <span
                                style={{fontSize: 13, color: item.eta > 0 ? '#3b8a36' : '#b54343', marginLeft: 6}}>
                                [{item.eta <= 0 ? '已上市' : item.eta === 1 ? '明天' : item.eta === 2 ? '后天' : `${item.eta} 天后`}]
                            </span> : ''}
                        </div>
                    </List.Item>
                )}
                size="small"
                split={false}
            />
        </div>
    );
}

export default ConvertibleBondWatchdog;
