import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom';
import './global.js';
import './index.css';
import {ConfigProvider, Grid, Layout, Menu} from "antd";
import {PropertySafetyOutlined,} from '@ant-design/icons';
import IndexHistory from "./views/index-history/IndexHistory";
import IndexConstituents from "./views/index-constituents/IndexConstituents";
import DividendDynamics from "./views/dividend-history/DividendDynamics";
import HoldingsProfitHistory from "./views/holdings-profit-history/HoldingsProfitHistory";
import IndexValue from "./views/index-value/IndexValue";
import IndexPondComparison from "./views/index-pond-comparison/IndexPondComparison";
import TradeRecord from "./views/trade-record/TradeRecord";
import HoldingsOccupation from "./views/holdings-occupation/HoldingsOccupation";
import IndexFundComparison from "./views/index-fund-comparison/IndexFundComparison";
import ProfitTable from "./views/profit-table/ProfitTable";
import AbsProfitTable from "./views/abstraction-profit-table/AbsProfitTable";
import Dashboard from "./views/dashboard/Dashboard";
import Login from "./views/login/Login";
import axios from 'axios';

import {BrowserRouter, Link, Route, Switch} from "react-router-dom";

const {useBreakpoint} = Grid;
const {Header, Content, Sider} = Layout;

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = "https://chihane.top/api/";
} else {
    axios.defaults.baseURL = "http://127.0.0.1:5000/";
}

axios.interceptors.request.use(
    function (request) {
        const token = localStorage.getItem('token');
        if (token) {
            request.headers.Authorization = token;
        }
        return request;
    }
);

axios.interceptors.response.use(
    function (response) {
        return response;
    }, function (error) {
        console.log(error);
        if (401 === error.response.status) {
            window.location.href = "/login";
        } else {
            return Promise.reject(error);
        }
    }
);

function Index() {
    const screens = useBreakpoint();

    const pathname = window.location.pathname.replace('/', '');

    if (pathname === 'login') {
        return <Login></Login>
    }

    const token = localStorage.getItem('token');
    if (!token) {
        return <Login></Login>
    }

    return (
        <BrowserRouter>
            <ConfigProvider locale={zhCN}>
                <Layout className='scaffold-layout'>
                    <Sider
                        id='sider'
                        theme='light'
                        collapsible
                        // defaultCollapsed
                        collapsedWidth="50"
                        style={{
                            overflow: 'auto',
                            height: '100vh',
                            marginBottom: 48,
                        }}>
                        <div className='div-sider-logo'>
                            <span className='logo'>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        outline: 0,
                                    }}
                                    href='/'
                                >
                                    INSOMNIA
                                </a>
                            </span>
                        </div>
                        <Menu theme="light"
                              mode="vertical"
                              defaultOpenKeys={['sub1', 'sub2']}
                              defaultSelectedKeys={pathname || 'dashboard'}
                        >
                            <Menu.Item key="dashboard">
                                <Link to='/dashboard'>监控中心</Link>
                            </Menu.Item>

                            <div className='sidebar-divider'/>

                            <Menu.Item key="profittable">
                                <Link to='/profittable'>盈亏表</Link>
                            </Menu.Item>
                            <Menu.Item key="absprofittable">
                                <Link to='/absprofittable'>大类盈亏表</Link>
                            </Menu.Item>
                            <Menu.Item key="holdingsprofit">
                                <Link to='/holdingsprofit'>持仓盈亏图</Link>
                            </Menu.Item>
                            <Menu.Item key="holdingsoccupations">
                                <Link to='/holdingsoccupations'>持仓构成</Link>
                            </Menu.Item>
                            <Menu.Item key="traderecords">
                                <Link to='/traderecords'>交易记录</Link>
                            </Menu.Item>
                            <Menu.Item key="dividenddynamics">
                                <Link to='/dividenddynamics'>分红动态</Link>
                            </Menu.Item>

                            <div className='sidebar-divider'/>

                            <Menu.Item key="indexvalue">
                                <Link to='/indexvalue'>指数估值</Link>
                            </Menu.Item>
                            <Menu.Item key="indexpondcomparison">
                                <Link to='/indexpondcomparison'>指债利比</Link>
                            </Menu.Item>
                            <Menu.Item key="indexhistory">
                                <Link to='/indexhistory'>指数历史</Link>
                            </Menu.Item>
                            <Menu.Item key="indexconstituents">
                                <Link to='/indexconstituents'>指数成分</Link>
                            </Menu.Item>
                            <Menu.Item key="indexfundcomparison">
                                <Link to='/indexfundcomparison'>指基比较</Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout>
                        <Header style={{
                            display: 'none',
                            backgroundColor: '#223',
                            padding: '0 16px',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        }}>
                            <div>
                                <PropertySafetyOutlined style={{
                                    color: 'white',
                                    fontSize: screens['sm'] ? 36 : 24,
                                    display: 'inline',
                                }}/>
                                <h1 style={{
                                    color: 'white',
                                    fontSize: screens['sm'] ? 36 : 24,
                                    fontFamily: 'SoukouMincho',
                                    fontWeight: 'bolder',
                                    marginLeft: 8,
                                    display: 'inline',
                                }}>
                                    北埃爾德里安二級市場監察署
                                    <span style={{
                                        fontSize: 18,
                                        marginLeft: 8,
                                        display: screens['xl'] ? 'inline' : 'none'
                                    }}>
                                [ North-Aldrean Aftermarket Supervisory Office ]
                            </span>
                                </h1>
                            </div>
                        </Header>
                        <Content style={{marginTop: 0, padding: 0, background: 'white'}}>
                            <div className="site-layout-background" style={{height: '100%'}}>
                                <Switch>
                                    <Route exact path="/">
                                        <Dashboard/>
                                    </Route>
                                    <Route path="/dashboard">
                                        <Dashboard/>
                                    </Route>
                                    <Route path="/profittable">
                                        <ProfitTable/>
                                    </Route>
                                    <Route path="/absprofittable">
                                        <AbsProfitTable/>
                                    </Route>
                                    <Route path="/holdingsprofit">
                                        <HoldingsProfitHistory/>
                                    </Route>
                                    <Route path="/holdingsoccupations">
                                        <HoldingsOccupation/>
                                    </Route>
                                    <Route path="/traderecords">
                                        <TradeRecord/>
                                    </Route>
                                    <Route path="/indexhistory">
                                        <IndexHistory/>
                                    </Route>
                                    <Route path="/indexvalue">
                                        <IndexValue/>
                                    </Route>
                                    <Route path="/indexpondcomparison">
                                        <IndexPondComparison/>
                                    </Route>
                                    <Route path="/indexconstituents">
                                        <IndexConstituents/>
                                    </Route>
                                    <Route path="/dividenddynamics">
                                        <DividendDynamics/>
                                    </Route>
                                    <Route path="/indexfundcomparison">
                                        <IndexFundComparison/>
                                    </Route>
                                </Switch>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </ConfigProvider>
        </BrowserRouter>
    );
}

ReactDOM.render(React.createElement(Index), document.getElementById('root'));
